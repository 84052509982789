
/* eslint-disable */
/** 
* This file contains language specific message functions for tree-shaking. 
* 
*! WARNING: Only import messages from this file if you want to manually
*! optimize your bundle. Else, import from the `messages.js` file. 
* 
* Your bundler will (in the future) automatically replace the index function 
* with a language specific message function in the build step. 
*/


	
/**
 * @param {{ projectTitle: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const activitiesNewMessageSubitle = (params) => `In ${params.projectTitle}`




	
/**
 * @param {{ projectTitle: NonNullable<unknown>, productTitle: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const activitiesNewMessageSubitleWithProduct = (params) => `In <strong>${params.projectTitle}</strong> on <strong>${params.productTitle}</strong>`




	
/**
 * @param {{ userName: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const activitiesNewMessageTitle = (params) => `${params.userName} left a message`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const activitiesNoActivities = () => `No activities yet`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const activitiesNoActivitiesDescription = () => `When someone leaves a message, it will appear here.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const activitiesNoActivitiesTitle = () => `You are all caught up!`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const areas = () => `Areas`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const chatNotFound = () => `Chat not found`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const companySettings = () => `Settings`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasAttic = () => `Attic`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasBasement = () => `Basement`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasBathroom = () => `Bathroom`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasBedroom = () => `Bedroom`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasCraftRoom = () => `Craft Room`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasDiningRoom = () => `Dining Room`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasFoyer = () => `Foyer`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasGameRoom = () => `Game Room`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasGarage = () => `Garage`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasGarden = () => `Garden`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasGuestRoom = () => `Guest Room`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasGym = () => `Gym`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasHomeOffice = () => `Home Office`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasHomeTheater = () => `Home Theater`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasKitchen = () => `Kitchen`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasLaundryRoom = () => `Laundry Room`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasLibrary = () => `Library`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasLivingRoom = () => `Living Room`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasMasterBedroom = () => `Master Bedroom`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasMudroom = () => `Mudroom`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasPantry = () => `Pantry`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasPatio = () => `Patio`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasSunroom = () => `Sunroom`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasSwimingPool = () => `Swiming Pool`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasTerrace = () => `Terrace`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const defaultAreasWineCellar = () => `Wine Cellar`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const homepageTagline = () => `The Ultimate Project Management Tool for Architects and Designers`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const logout = () => `Logout`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const newProject = () => `New Project`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const members = () => `Members`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productNotFound = () => `Product not found`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productsBatchUpdate = () => `Update`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productsBatchUpdateModalClose = () => `Cancel`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productsBatchUpdateModalCta = () => `Update`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productsBatchUpdateModalTitle = () => `Update products`




	
/**
 * @param {{ count: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productsBatchUpdateModalWarning = (params) => `You are about to update ${params.count} products.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productsCount = () => `Products`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productsEditArea = () => `Area`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productsEditState = () => `State`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productsEditTypes = () => `Types`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productsEmptyClearFilters = () => `clear filters`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productsEmptyImageAlt = () => `No results`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productsEmptySubtitle = () => `Please search update or`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productsEmptyTitle = () => `We didn't find any products for your search`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productsExport = () => `Export`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productsExportInvoice = () => `Invoice`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productsExportPurchaseOrder = () => `Purchase Order`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productsFiltersClearAll = () => `Clear filters`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productsSearchByTitle = () => `Search by title`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productsSelectAll = () => `Select all visible`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productStatesDisliked = () => `Disliked`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productStatesInstalled = () => `Installed`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productStatesLiked = () => `Liked`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productStatesOrdered = () => `Ordered`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productStatesProposed = () => `Proposed`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productStatesReceived = () => `Received`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productStatesShipped = () => `Shipped`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productStatesUnspecified = () => `Unspecified`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productStatesValidated = () => `Validated`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productTypesEmpty = () => `This project currently has no types. Create a new type by typing in the text box and pressing Enter`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productTypesPlaceholder = () => `Select or create a type`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const projectNavChat = () => `Chat`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const projectNavProducts = () => `Products`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const projectNavSettings = () => `Settings`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const projectNewDescriptionLabel = () => `Description`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const projectNewSubmit = () => `Save`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const projectNewTitleLabel = () => `Title`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const projectEditSubmit = () => `Update`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const projectNotFound = () => `Project not found`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const projects = () => `Projects`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signIn = () => `Sign In`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signInTitle = () => `Sign in to your account`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signInEmail = () => `Email`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signInPassword = () => `Password`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signInForgotPassword = () => `Forgot password?`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signUp = () => `Sign Up`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signUpFirstName = () => `First Name`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signUpLastName = () => `Last Name`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signUpEmail = () => `Email`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signUpPassword = () => `Password`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const signUpPasswordConfirmation = () => `Password Confirmation`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sortedBy = () => `Sorted by`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sortedByArea = () => `Area`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sortedByCreatedAt = () => `Created at`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sortedByState = () => `State`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const states = () => `States`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const types = () => `Types`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const userSettings = () => `Account`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const large_polite_gibbon_relish = () => `Must be a valid email`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const dull_that_mole_grip = () => `Password must be atleast 6 characters`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const wacky_bold_fireant_mop = () => `Confirm Password is required`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const big_known_anteater_snap = () => `Password don't match`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lost_away_finch_dine = () => `Firstname is required`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const calm_strong_crow_read = () => `Lastname is required`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const petty_bright_dog_compose = () => `Email is required`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const chunky_light_skunk_hike = () => `Invalid username or password`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const topical_frail_polecat_dial = () => `Success!`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const tasty_drab_lion_beam = () => `Title`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const left_vexed_ocelot_dial = () => `Save`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fresh_icy_nuthatch_cuddle = () => `You don't have any companies yet.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fun_upper_cobra_type = () => `New Company`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const free_this_dragonfly_gulp = () => `Are you sure you want to transfer ownership?\\nYou'll be demoted to an admin.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const basic_still_lionfish_pull = () => `Invite new team member`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const curly_drab_niklas_fold = () => `Pending invitations`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const such_muddy_tapir_hike = () => `Members`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const zany_few_bee_ask = () => `An invitation has already been sent to this email.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const cozy_topical_finch_urge = () => `This user is already a member of the company.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const home_plane_koala_quell = () => `New company invitation!`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const merry_brief_dachshund_pinch = () => `Hello world`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const extra_male_anaconda_treat = () => `Email`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const vexed_noisy_vulture_hurl = () => `Send Invitation`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const direct_ago_thrush_believe = () => `Cancel`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bold_home_iguana_revive = () => `New Project`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const deft_sad_loris_roar = () => `Generate Invoice`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const tiny_smart_stork_splash = () => `Invoice Number`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const due_lazy_pug_heart = () => `Purchase Order #`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const large_bald_duck_spin = () => `Date`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const loud_main_poodle_dig = () => `Due Date`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const keen_ago_cockroach_aid = () => `Note (top page)`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const hour_legal_firefox_zip = () => `Preview`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sad_clear_otter_feast = () => `Close`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const crazy_bald_bear_ask = () => `Invoice`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const antsy_helpful_mayfly_treasure = () => `Description`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const large_steep_jaguar_push = () => `Quantity`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bald_steep_dingo_belong = () => `Tax`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const low_sour_tuna_ripple = () => `Price`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const tangy_quaint_shrike_dare = () => `Subtotal`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const glad_sharp_cobra_flop = () => `Tax`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const slimy_tasty_goose_care = () => `Shipping`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pink_stale_frog_race = () => `Discount`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const close_good_lobster_achieve = () => `Total`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const north_main_ox_ripple = () => `Create`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const equal_home_hyena_harbor = () => `Cancel`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const jolly_still_sloth_smile = () => `Edit`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const major_extra_camel_dare = () => `Comments`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const away_shy_jaguar_jolt = () => `Save`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const that_flaky_pelican_cheer = () => `An unexpected error occurred`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const patient_next_hyena_win = () => `An unexpected error occurred`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const new_true_rooster_attend = () => `Edit`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const soft_brave_herring_splash = () => `Client`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const alive_zippy_kestrel_flow = () => `Members`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const raw_tiny_husky_fry = () => `Archive`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const polite_wild_mole_slide = () => `Archive`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const topical_loved_kangaroo_offer = () => `Invite Client`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const such_trite_rat_slurp = () => `Pending invitations`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const clean_teal_toucan_zap = () => `Clients`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const clean_solid_polecat_grace = () => `Client Name`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const only_clean_giraffe_flow = () => `Billing Address`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const maroon_novel_maggot_bake = () => `Shipping address is the same as billing address.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const inclusive_ago_firefox_create = () => `Shipping Address`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const every_spicy_bobcat_bake = () => `An invitation has already been sent to this email.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fit_flaky_fish_blend = () => `This user is already a client of the project.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const busy_small_sawfish_hug = () => `New project invitation!`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const topical_dark_jackal_conquer = () => `Hello world`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const dry_tiny_platypus_feel = () => `Email`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const raw_spicy_ibex_race = () => `Invite new client`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const slimy_quick_beetle_flip = () => `Send Invitation`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const loved_sad_deer_grace = () => `Cancel`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const chunky_strong_jannes_sew = () => `Are you sure you want to transfer ownership?\\nYou'll be demoted to an admin.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const awake_suave_sawfish_spin = () => `Invite new team members`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const white_that_frog_learn = () => `Role`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const such_full_canary_build = () => `If the user is not listed above, please add them to the company members first.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const home_happy_mole_devour = () => `Go to the company's members list`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const day_muddy_leopard_dance = () => `Add member`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const late_sharp_toad_heal = () => `Cancel`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const loud_civil_racoon_feel = () => `Looks like it's quiet in here...
<br />
🌟 Be the spark! Ignite the chat now!
`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const nimble_super_impala_dart = () => `Send`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const inner_equal_lemur_delight = () => `Type a message...`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const stout_agent_dachshund_buzz = () => `Invite new team members`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const direct_funny_falcon_care = () => `Invite new team member`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const such_mild_javelina_embrace = () => `Members`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const few_frail_iguana_taste = () => `There are no products for this project yet.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const known_next_haddock_taste = () => `Create one!`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const cuddly_fuzzy_fly_spin = () => `Forgot Password`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const east_new_trout_pinch = () => `Enter the email address associated with your account and we'll send
          you a link to reset your password.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const white_proud_larva_blend = () => `Send instructions`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const teal_key_bulldog_animate = () => `Back to sign in page`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const suave_safe_larva_laugh = () => `Email`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const teal_crazy_shrimp_foster = () => `Your password has been updated!`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const safe_weary_duck_sail = () => `You can now sign in with your newly created password`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const zippy_elegant_alpaca_mop = () => `Go to sign in page`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const busy_loose_bee_peel = () => `Choose a new password`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bald_lower_nils_intend = () => `Password`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lower_short_mammoth_renew = () => `Password confirmation`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_crazy_toucan_chop = () => `Reset Password`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const heroic_dizzy_mayfly_dare = () => `Back to sign in page`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const dark_this_boar_agree = () => `Projects`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const free_mellow_tapir_quell = () => `Role`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const active_helpful_grebe_pull = () => `has invited you to join`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const tangy_born_rat_clap = () => `Would you like to accept?`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const wild_helpful_quail_wish = () => `Accept`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const nimble_arable_stork_advise = () => `Decline`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const wild_arable_stork_advise = () => `Company invitation`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const gaudy_heavy_firefox_ascend = () => `Project Invitation`




	
/**
 * @param {{ companyName: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const basic_formal_hawk_spur = (params) => `Join <strong>${params.companyName}</strong>`




	
/**
 * @param {{ invitedByUsername: NonNullable<unknown>, companyName: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const cozy_minor_pony_laugh = (params) => `<strong>${params.invitedByUsername}</strong> has invited you to join <strong>${params.companyName}</strong>.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const loved_spare_toucan_fold = () => `Join the company`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const dirty_aqua_grizzly_dine = () => `or copy and paste this URL into your browser:`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const muddy_soft_jellyfish_gaze = () => `<strong>Note:</strong> This link will expire in 48 hours.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const still_antsy_zebra_grace = () => `Hello,`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const main_heroic_poodle_charm = () => `Projects`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const witty_calm_octopus_whisper = () => `Simplifiez la collaboration entre votre équipe et vos clients`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sleepy_brave_panda_dance = () => `Archispec est la solution web conçue pour fluidifier et optimiser vos échanges avec vos clients tout au long de vos projets.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const curious_swift_gazelle_leap = () => `Sign up`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const gentle_wise_owl_blink = () => `Recherchez facilement les produits parfaits`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lively_kind_dolphin_splash = () => `Explore the web or consult your own database to find the products best suited to your projects.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const clever_quiet_fox_sneak = () => `Present products to your customers`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const happy_fluffy_bunny_hop = () => `Partypeez facilement vos sélections de produits avec vos clients via une interface intuitive.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const brave_steady_turtle_crawl = () => `Get instant feedback`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const playful_agile_monkey_swing = () => `Your customers can comment on, approve or reject products in just a few clicks, making it easier for them to make the right decision.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const swift_graceful_deer_prance = () => `Generate purchase orders with one click`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const patient_wise_elephant_trumpet = () => `Automate the creation of purchase orders for validated products, sent directly to your suppliers.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const loyal_strong_wolf_howl = () => `Track progress in real time`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const curious_nimble_squirrel_scurry = () => `Keep your customers informed about the progress of each product, from order to delivery.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const elegant_calm_swan_glide = () => `Simplify invoice management`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const friendly_playful_otter_swim = () => `Send invoices to your customers quickly and effortlessly.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const majestic_wise_eagle_soar = () => `Archispec a révolutionné notre façon de collaborer avec nos clients. C'est un outil indispensable pour une gestion simplifiée et une communication fluide.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const eloquent_gentle_butterfly_flutter = () => `Thanks to Archispec, we have considerably reduced the time spent on administrative management, enabling us to concentrate more on creativity and customer satisfaction.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const brilliant_serene_hummingbird_hover = () => `La facilité d'utilisation d'Archispec combinée à sa puissance fonctionnelle en fait un atout majeur pour notre entreprise. Nos projets n'ont jamais été aussi bien organisés.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const clever_calm_penguin_design = () => `Interior designer`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const witty_brave_lion_create = () => `Designer produit`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const gentle_wise_owl_decorate = () => `Interior designer`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const eager_bright_flamingo_expect = () => `Our value proposition`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const curious_happy_parrot_speak = () => `Ce que disent nos clients`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const long_lazy_puffin_heal = () => `Product status update`




	
/**
 * @param {{ user: NonNullable<unknown>, product: NonNullable<unknown>, state: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const weird_alert_bumblebee_offer = (params) => `${params.user} has set ${params.product} to ${params.state}.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sleek_minor_leopard_honor = () => `Clear all`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const main_whole_mammoth_arise = () => `Enter a concise, descriptive title for your product.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const male_formal_lamb_savor = () => `Provide a detailed description of your product, including its features and benefits.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const house_smart_ray_tear = () => `Upload up to 5 images showcasing your product. These will be displayed in the product gallery.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sea_tangy_barbel_cure = () => `Select the area this product belongs to within your project.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const tame_nice_baboon_pout = () => `Choose the current state or status of your product.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const swift_patchy_herring_cuddle = () => `Add relevant types to help categorize and search for your product.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const spicy_bland_llama_delight = () => `Already have an account?`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const arable_awful_pig_sprout = () => `Don't have an account yet?`




	
/**
 * @param {{ date: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const red_wide_bullock_evoke = (params) => `Liked ${params.date} ago.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lofty_muddy_husky_roam = () => `Validate`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mellow_drab_panther_coax = () => `You have validated this product. You will let you know when it is ordered.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const alive_tame_ocelot_pout = () => `You can now validate it. This will allow us to place the order as soon as possible.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const game_grand_cat_edit = () => `Tell us if you like it or not.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const civil_stale_shrimp_slurp = () => `We will place the order as soon as possible and keep you informed.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const tasty_yummy_eagle_emerge = () => `The order has been placed. We will inform you when it is shipped.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const gross_brave_mantis_thrive = () => `Le produit a été expédié. Nous vous informerons lorsqu'il sera reçu.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const known_agent_tapir_climb = () => `The product has been installed.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const frail_fuzzy_felix_arise = () => `We will find something else that suits you.`




	
/**
 * @param {{ date: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const great_gross_hedgehog_affirm = (params) => `Proposed ${params.date} ago`




	
/**
 * @param {{ date: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const such_these_nuthatch_play = (params) => `Disliked ${params.date} ago`




	
/**
 * @param {{ date: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const witty_early_leopard_forgive = (params) => `Validated ${params.date} ago`




	
/**
 * @param {{ date: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const any_actual_shrike_zap = (params) => `Ordered ${params.date} ago`




	
/**
 * @param {{ date: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const big_patient_crocodile_devour = (params) => `Shipped ${params.date} ago`




	
/**
 * @param {{ date: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const warm_sea_toad_relish = (params) => `Received ${params.date} ago`




	
/**
 * @param {{ date: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const kind_heavy_frog_boil = (params) => `Installed ${params.date} ago`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sharp_weak_opossum_zoom = () => `The product has been received. We will inform you when it is installed.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const moving_flaky_felix_link = () => `Change your mind`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const aloof_busy_termite_nail = () => `Confirm Validation`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const aloof_still_snail_renew = () => `Are you sure you want to validate this product? This action cannot be reversed.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const teary_whole_skunk_cherish = () => `Cancel`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lucky_tasty_ant_work = () => `Confirm`




	
/**
 * @param {{ state: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const north_civil_mule_imagine = (params) => `${params.state}`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productStateProposed = () => `Proposed`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productStateLiked = () => `Liked`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productStateDisliked = () => `Disliked`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productStateValidated = () => `Validated`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productStateOrdered = () => `Ordered`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productStateShipped = () => `Shipped`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productStateReceived = () => `Received`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const productStateInstalled = () => `Installed`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const dirty_dry_marlin_zip = () => `You changed your mind`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const jolly_actual_gorilla_revive = () => `Your product showcase is coming soon!`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const flat_aware_yak_pat = () => `We do our utmost to offer you quality products, perfectly adapted to your needs.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lazy_fit_beetle_roam = () => `You will be notified when we add something!`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const brief_light_pug_gaze = () => `While you wait, why not drop us a message? We'd love to hear from you!`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const candid_round_millipede_absorb = () => `Start a conversation`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const these_true_platypus_enrich = () => `Comments`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bland_ideal_turkey_grin = () => `Info`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const red_loved_liger_nurture = () => `Markup`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sunny_clear_moth_treat = () => `Sales Tax (%)`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const salty_tasty_baboon_offer = () => `Shipping`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const short_ornate_raven_hope = () => `Discount`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const known_moving_loris_nail = () => `Currency`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const honest_good_crocodile_hush = () => `MSRP`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const mean_heroic_earthworm_catch = () => `Total price`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const unit_piece = () => `Piece`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const unit_kg = () => `Kilogram`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const unit_liter = () => `Liter`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const unit_meter = () => `Meter`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const unit_sqm = () => `Square Meter`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const unit_set = () => `Set`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const helpful_pretty_lemur_zip = () => `Supplier`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const alert_day_owl_fond = () => `Suppliers`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lofty_whole_pug_twirl = () => `manufacturers`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pink_misty_cheetah_flop = () => `Manufacturer`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const nice_due_barbel_fear = () => `Import a product`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const that_sharp_chipmunk_zoom = () => `To quickly import a product, enter the URL of the product page from any e-commerce site. Our AI will attempt to extract the product details for you.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sharp_least_raven_catch = () => `Doing some AI magic...`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const solid_mad_quail_pray = () => `Autofill`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const close_witty_penguin_support = () => `Enter product URL`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const equal_royal_polecat_find = () => `Enter a concise, descriptive title for your product.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const green_weary_platypus_emerge = () => `Provide a detailed description of your product, including its features and benefits.`




	
/**
 * @param {{ count: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const main_misty_lionfish_slide = (params) => `Upload up to ${params.count} images showcasing your product. These will be displayed in the product gallery.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const happy_factual_mare_breathe = () => `Select the area or category this product belongs to within your project.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sound_house_jaguar_devour = () => `Select the supplier for this product.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const real_good_clownfish_talk = () => `Select the manufacturer for this product.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bad_icy_mare_tap = () => `Choose the current state or status of your product in the development process.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const level_silly_gazelle_fulfill = () => `Add relevant types to help categorize and search for your product.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pink_proof_canary_type = () => `Enter the quantity and select the unit of measurement for the product.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const watery_still_alpaca_aim = () => `Enter the pricing details for the product.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const empty_mean_hornet_bake = () => `Title`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const nice_spare_wallaby_amuse = () => `Description`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pretty_fine_sawfish_splash = () => `State`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ago_last_myna_intend = () => `Quantity`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const helpful_mean_turtle_ask = () => `Unit`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const least_crisp_samuel_snip = () => `City`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const tough_jolly_butterfly_comfort = () => `Zip`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const topical_pretty_sloth_bump = () => `State`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const wide_aloof_ox_trim = () => `Country`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const short_just_barbel_mix = () => `Street`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bald_smug_fish_enrich = () => `Enter one or more image URLs, each on a new line`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const petty_tough_eagle_quell = () => `Image URLs`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const salty_north_weasel_scoop = () => `You have reached the maximum number of files you can upload.
Feel free to delete one of the existing files to make room for a new one.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lofty_arable_rabbit_coax = () => `Pick a file`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const least_soft_bulldog_strive = () => `Upload Failed`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const slow_steep_tiger_zip = () => `There was an error uploading your file. Please try again.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const last_neat_koala_prosper = () => `Image Retrieval Failed`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const calm_wide_tern_pause = () => `We couldn't retrieve the image from the provided URL. Please check that the URL is correct and try again.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const wise_silly_jellyfish_loop = () => `User`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const white_dry_ladybug_arrive = () => `Select role`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const polite_noisy_vole_feast = () => `Client Name`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bold_many_crow_fall = () => `Client`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const civil_house_iguana_mend = () => `date`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const gray_witty_squid_zoom = () => `Anything else?`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const male_petty_ray_mop = () => `date`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sunny_direct_canary_savor = () => `If an account with the provided email exists, we've sent a link to reset your password. Please check your inbox for further instructions.
              If you don’t receive an email, be sure to check your spam or junk folder.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sound_brave_gecko_glow = () => `Password Reset Request Submitted`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const heavy_least_sheep_hunt = () => `Reset your password`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const blue_formal_trout_view = () => `Upload from computer`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const simple_salty_beaver_bubble = () => `OR`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const petty_warm_platypus_taste = () => `Upload URLs`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const good_just_mare_conquer = () => `Max files reached`




	
/**
 * @param {{ maxFilesCount: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const caring_drab_falcon_yell = (params) => `you can't upload more than ${params.maxFilesCount} files`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const broad_super_anaconda_scold = () => `Hello,`




	
/**
 * @param {{ invitedByUsername: NonNullable<unknown>, projectName: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const raw_only_goldfish_urge = (params) => `<strong>${params.invitedByUsername}</strong> has invited you to join <strong>${params.projectName}</strong>.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const east_bad_badger_trust = () => `Join the project`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const late_dizzy_elephant_lock = () => `or copy and paste this URL into your browser:`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const yummy_bland_badger_enchant = () => `<strong>Note:</strong> This link will expire in 48 hours.`




	
/**
 * @param {{ projectName: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const super_elegant_grizzly_dig = (params) => `Join <strong>${params.projectName}</strong>`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const home_cute_wolf_nudge = () => `Reset Password Request`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const tiny_pretty_piranha_blink = () => `Hello,`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const hour_hour_manatee_endure = () => `We received a request to reset the password for your account.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const petty_zany_rook_lend = () => `If you made this request, please click on the link below to reset your
        password:`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const only_noisy_koala_beam = () => `Reset Password`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fluffy_shy_oryx_rise = () => `or copy and paste this URL into your browser:`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const grassy_round_bird_evoke = () => `This link will expire in 24 hours for security reasons. If you did not
      request a password reset, please ignore this email or contact us if you
      have any concerns.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const brave_less_reindeer_hike = () => `Add to project`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const calm_novel_jan_reap = () => `Select a project`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const topical_great_puma_surge = () => `Add to project`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lost_swift_okapi_cook = () => `Add to project`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const grassy_large_warthog_lift = () => `Add to project`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const proud_top_scallop_find = () => `Library`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fresh_tangy_wren_jump = () => `Note (bottom page)`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const trite_safe_toad_pave = () => `Download Invoice`




	
/**
 * @param {{ date: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const less_legal_termite_nudge = (params) => `${params.date}`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const muddy_mushy_giraffe_clasp = () => `LLLL Lo, yyyy`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const patient_solid_marmot_assure = () => `Payment Details`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const low_acidic_alpaca_spur = () => `#`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const alive_glad_marlin_type = () => `Include Taxes`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fine_blue_poodle_bloom = () => `Payment Details`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const wacky_tidy_seahorse_jump = () => `Discount`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const inclusive_knotty_jackal_mop = () => `Shipping`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const pink_few_tiger_spur = () => `0`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bold_odd_mole_seek = () => `0`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const soft_kind_lynx_love = () => `Merci pour votre commande`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const raw_that_mallard_grow = () => `IBAN : FR7630001007941234567890185`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const sea_petty_turtle_honor = () => `yyyy-MM-dd`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const deft_civil_hamster_offer = () => `yyyy-MM-dd`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const active_spicy_spider_relish = () => `Associate`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bright_tired_yak_exhale = () => `Admin`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const main_only_squirrel_earn = () => `Owner`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ago_that_lobster_hint = () => `Client`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lime_lofty_firefox_clip = () => `Images Size`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const bad_sweet_fly_grin = () => `Areas`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const last_lazy_felix_dash = () => `Duplicate`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lazy_dull_scallop_build = () => `Cancel`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const good_green_reindeer_vent = () => `Duplicate product to another area`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const dark_mealy_loris_pray = () => `No area`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const gross_empty_mayfly_roam = () => `Are you sure you want to delete this area?\\nIt will move all products in this area to no area.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const flat_deft_haddock_yell = () => `New Area`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const lime_frail_mantis_dream = () => `Name of the new area`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const stock_dirty_capybara_cheer = () => `Drag and drop areas to reorder them`




	
/**
 * @param {{ product: NonNullable<unknown> }} params
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const basic_wide_mayfly_grin = (params) => `Are you sure you want to delete "${params.product}"?
This action cannot be undone.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const quick_sunny_lark_sew = () => `Delete`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const civil_clear_liger_amuse = () => `Cancel`

