import { SerializeFrom } from "@remix-run/node";
import { createContext } from "react";
import { Address, Prisma, Product } from "@prisma/client";

type SearchContextType = SerializeFrom<{
  selectedProducts?: SerializeFrom<[]>;
  project?: {
    areas: {
      id: number;
      name: string;
    }[];
    types: {
      id: number;
      name: string;
    }[];
    company: {
      name: string;
      address: Address | null;
    };
    client: {
      name: string;
      billingAddress: Address | null;
      shippingAddress: Address | null;
    };
  };
  states?: {
    id: number;
    name: string;
  }[];
}> & {
  updateProducts: (products: []) => void;
  addProduct: (product) => void;
  deleteProduct: (product) => void;
};

type ProductsSearchContextType = SerializeFrom<{
  updateProducts: (products: SerializeFrom<Partial<Product>[]>) => void;
}>;

export const SearchContext = createContext<SearchContextType | undefined>(
  undefined,
);

export const ProductsSearchContext = createContext<
  ProductsSearchContextType | undefined
>(undefined);

export type Activity = {
  id: number;
  type: string;
  meta: Prisma.JsonValue;
  chatId: number | null;
  user: {
    id: number;
    firstName: string;
    lastName: string;
  };
  product: {
    id: number;
    title: string;
    state: {
      id: number;
      name: string;
    } | null;
  } | null;
  project: {
    id: number;
    title: string;
  } | null;
  invitation: {
    id: string;
    project: {
      id: number;
      title: string;
    } | null;
    company: {
      id: number;
      name: string;
    } | null;
  } | null;
  createdAt: Date;
};

export type ActivitiesContextType = {
  activities: SerializeFrom<Activity[]>;
  setActivities: React.Dispatch<
    React.SetStateAction<SerializeFrom<Activity[]>>
  >;
};

export const ActivitiesContext = createContext<ActivitiesContextType>({
  activities: [],
  setActivities: () => [],
});
